import React from 'react'
import About from '../components/about/About'
import Envelope from '../images/envelope.png'
import SEO from '../components/seo'
import SideBar from '../components/sideBar/SideBar'
import TwoColumnLayout from '../components/twoColumnLayout/TwoColumnLayout'
import styles from './../page-css/contact.module.css'
import ContactForm from '../components/ContactForm/ContactForm'

const Contact = () => (
  <TwoColumnLayout>
    <SEO title="Contact Us" />
    <div>
      <h1 className={styles.title}>Contact Us</h1>
      <div className={styles.contactContainer}>
        <div className={styles.message}>
          <img src={Envelope} alt={'envelope'} />
        </div>

        <div className={styles.form}>
          <ContactForm />
        </div>
      </div>
    </div>
    <SideBar>
      <About />
    </SideBar>
  </TwoColumnLayout>
)

export default Contact
