import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styles from './about.module.css'

const About = () => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "cactus-logo.png" }) {
        id
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <section className={styles.about}>
      <span className={styles.imgContainer}>
        <Img fixed={data.image.childImageSharp.fixed} alt="Cactus Atlas Logo" />
      </span>
      <span>
        Greetings fellow adventurers! Explore the American Southwest with us! We
        provide rich{' '}
        <Link className={styles.link} to="/information">
          information
        </Link>{' '}
        about many unique and beautiful destinations through{' '}
        <Link to="/videos" className={styles.link}>
          videos
        </Link>
        ,{' '}
        <Link to="/adventures" className={styles.link}>
          blogs
        </Link>
        ,{' '}
        <Link className={styles.link} to="/camping">
          campground reviews
        </Link>{' '}
        and data gathered from reputable sources.{' '}
        <Link className={styles.link} to="/about">
          Click here
        </Link>{' '}
        to learn more!
      </span>
    </section>
  )
}

export default About
