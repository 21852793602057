// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React, { useState } from 'react'
import styles from '../../page-css/contact.module.css'
import ReCAPTCHA from 'react-google-recaptcha/'
import MediaQuery from '../sharedComponents/MediaQuery/MediaQuery'

const queries = {
  mobile: '(max-width: 421px)',
  desktop: '(min-width: 422px)',
}

const CompactRecaptcha = () => (
  <ReCAPTCHA
    sitekey="6LdLLDUaAAAAAIjvTlKcbm43nnNTF3wq94z3fpDc"
    onChange={onChange}
    size="compact"
  />
)

const DesktopRecaptcha = () => (
  <ReCAPTCHA
    sitekey="6LdLLDUaAAAAAIjvTlKcbm43nnNTF3wq94z3fpDc"
    onChange={onChange}
    size="normal"
  />
)

function onChange(value) {
  console.log('Captcha value:', value)
}

const ContactForm = () => {
  const [status, setStatus] = useState('')
  const matchPoints = MediaQuery(queries)
  const submitForm = ev => {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
    }
    xhr.send(data)
  }

  return (
    <form
      onSubmit={submitForm}
      action="https://formspree.io/f/mgepryea"
      method="POST"
    >
      <label>Email:</label>
      <div className={styles.input}>
        <input type="email" name="email" />
      </div>
      <div className={styles.input}>
        <label>
          Your Full Name:
          <br />
          <input type="text" name="name" id="name" />
        </label>
      </div>
      <div className={styles.input}>
        <label>
          Your Company Name (if applicable):
          <input type="text" name="company" id="company" />
        </label>
      </div>
      <div className={styles.input}>
        <label>
          Subject:
          <input type="text" name="subject" id="subject" />
        </label>
      </div>
      <div className={styles.input}>
        <label>Message:</label>
        <textarea name="message" rows="5" />
      </div>
      <input className={styles.ohnohoney} type="text" name="_gotcha" />
      <div className={styles.recaptcha}>
        {matchPoints && matchPoints.mobile ? <CompactRecaptcha /> : null}
        {matchPoints && matchPoints.desktop ? <DesktopRecaptcha /> : null}
      </div>
      {status === 'SUCCESS' ? (
        <p className={styles.success}>Thanks so much for reaching out!</p>
      ) : (
        <button>Submit</button>
      )}
      {status === 'ERROR' && (
        <p className={styles.error}>Ooops! There was an error.</p>
      )}
    </form>
  )
}

export default ContactForm
